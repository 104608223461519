<template>
  <div class="data_panel_box">
    <!--数据面板卡片-->
    <Row :gutter="24" class="card_box">
      <Col span="6" class="pb24" v-for="(item, index) in dataPanelList" :key="index">
        <div class="card_box_item">
          <div class="title_box">
            <h2 class="title">{{ item.symbol ? (item.title + '（' + currencyState + '）') : item.title }}</h2>
            <Tooltip :placement="item.placement" class="reset_tooltip_style">
              <Icon type="ios-alert-outline" size="20" color="#2D8CF0" class="cursor"/>
              <div slot="content" style="white-space: normal; width:400px">{{ item.tooltipContent }}</div>
            </Tooltip>
          </div>
          <div class="account">
            <span class="currency" v-if="item.symbol">￥</span>
            <span class="price">{{ priceTransform(item.total) }}</span>
          </div>
          <div class="relevant_content" v-for="(ele,idx) in item.list" :key="idx">
            <span class="relevant_title">{{ ele.title }}</span>
            <div class="flex align-items">
              <span class="currency" v-if="ele.symbol">￥</span>
              <span class="price">{{ priceTransform(ele.value) }}</span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <!--订单状态、商品管理-->
    <Row :gutter="24">
      <Col span="12" class="pb24">
        <Card :bordered="false" dis-hover class="card_content_box">
          <template #title>
            <h2 class="title_box">{{ $t('key1002204') }}</h2>
          </template>
          <div class="order_status_box">
            <div class="status_item_styles" v-for="(item,index) in orderStatusList" :key="index">
              <h3 class="title">{{ item.title }}</h3>
              <span class="text">{{ item.value }}</span>
            </div>
          </div>
        </Card>
      </Col>
      <Col span="12" class="pb24">
        <Card :bordered="false" dis-hover class="card_content_box">
          <template #title>
            <div class="title_content">
              <h2 class="title_box">{{ $t('key1005814') }}</h2>
              <router-link tag="a" target="_blank" class="text" to="/productCenter/productGoods">{{ $t('key1000019') }}</router-link>
            </div>
          </template>
          <div class="commodity_status_box">
            <div class="commodity_status_content">
              <div class="status_item_styles hoverStyles" v-for="(item,index) in commodityStatusList" :key="index" @click="selectCommodityBtn(item)">
                <h3 class="title">{{ item.title }}</h3>
                <span class="text">{{ item.value }}</span>
              </div>
            </div>
            <div class="line_box"></div>
            <router-link class="release_products_box" tag="a" target="_blank" to="/productDetails?type=add">
              <Icon type="md-add" color="#2D8CF0" size="32"/>
              <h3 class="title">{{ $t('key1005815') }}</h3>
            </router-link>
          </div>
        </Card>
      </Col>
    </Row>
    <!--销售趋势图、热销商品排行榜-->
    <Row :gutter="24">
      <Col span="12" class="pb24">
        <Card :bordered="false" dis-hover class="card_content_box reset_card_style">
          <template #title>
            <div class="flex justify-content-between align-items-center" style="width: 100%;">
              <h2 class="title_box">{{ $t('key1005816') }}</h2>
              <div class="options_box">
                <div class="date_box">
                  <span v-for="(item,index) in dateList" class="date_box_item" :key="index" :class="item.active? 'activeColor' : ''"
                      @click="selectDateBtn(item,index)"> {{ item.title }}</span>
                </div>
                <Select v-model="trendType" style="width:110px" @on-change="selectChange">
                  <Option v-for="(item,index) in trendTypeList" :value="item.value" :key="index">{{ item.title }}</Option>
                </Select>
              </div>
            </div>
          </template>
          <div id="main" style="width:100%;height:400px;"></div>
        </Card>
      </Col>
      <Col span="12" class="pb24">
        <Card :bordered="false" dis-hover class="card_content_box">
          <template #title>
            <h2 class="title_box">{{ $t('key1005817') }}</h2>
          </template>
          <div class="table_box">
            <Table
                max-height="400"
                :loading="tableLoading"
                :columns="tableColumns"
                :data="tableData">
            </Table>
          </div>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api';
import {setConfigCharacters} from "@/utils/common";

var myCharts;
export default {
  name: 'statistics',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      dataPanelList: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002176'),
          symbol: true,
          total: 0,
          placement: 'top',
          paramsKey: 'monthSalesVolume',
          tooltipContent: `${alias47916751af154eb5b47cd5de9d34633d.t('key1005818')} (${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005819')}) ${alias47916751af154eb5b47cd5de9d34633d.t('key1005820')}`,
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005823'), paramsKey: 'lastMonthSalesVolume', symbol: true, value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002177'), paramsKey: 'yesterdaySalesVolume', symbol: true, value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002178'), paramsKey: 'todaySalesVolume', symbol: true, value: 0}
          ]
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1005824'),
          total: 0,
          placement: 'top',
          paramsKey: 'monthOrderQuantity',
          tooltipContent: `${alias47916751af154eb5b47cd5de9d34633d.t('key1005821')} (${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005819')}) ${alias47916751af154eb5b47cd5de9d34633d.t('key1005822')}`,
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005825'), paramsKey: 'lastMonthOrderQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005826'), paramsKey: 'yesterdayOrderQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005827'), paramsKey: 'todayOrderQuantity', value: 0}
          ]
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1005828'),
          total: 0,
          placement: 'top',
          paramsKey: 'onSaleSpuQuantity',
          tooltipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1005829'),
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002184'), paramsKey: 'monthShelveSpuQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002185'), paramsKey: 'yesterdayShelveSpuQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002186'), paramsKey: 'todayShelveSpuQuantity', value: 0}
          ]
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1005830'),
          total: 0,
          placement: 'top-end',
          paramsKey: 'onSaleSkuQuantity',
          tooltipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1005831'),
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002184'), paramsKey: 'monthShelveSkuQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002185'), paramsKey: 'yesterdayShelveSkuQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002186'), paramsKey: 'todayShelveSkuQuantity', value: 0}
          ]
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1005832'),
          total: 0,
          placement: 'top',
          paramsKey: 'totalProductSubscription',
          tooltipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1005833'),
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005834'), paramsKey: 'monthProductSubscription', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005835'), paramsKey: 'yesterdayProductSubscription', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005836'), paramsKey: 'dayProductSubscription', value: 0}
          ]
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002191'),
          total: 0,
          placement: 'top',
          paramsKey: 'shelfQuantity',
          tooltipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1005837'),
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002192'), paramsKey: 'monthShelfQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002193'), paramsKey: 'yesterdayShelfQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002194'), paramsKey: 'todayShelfQuantity', value: 0}
          ]
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002195'),
          total: 0,
          placement: 'top',
          paramsKey: 'publishQuantity',
          tooltipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1005838'),
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002196'), paramsKey: 'monthPublishQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002197'), paramsKey: 'yesterdayPublishQuantity', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1002198'), paramsKey: 'todayPublishQuantity', value: 0}
          ]
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1005839'),
          total: 0,
          placement: 'top-end',
          paramsKey: 'thirtyDayOnShipments',
          tooltipContent: alias47916751af154eb5b47cd5de9d34633d.t('key1005840'),
          list: [
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005841'), paramsKey: 'sevenDayOnShipments', value: 0},
            {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005842'), paramsKey: 'threeDayOnShipments', value: 0}
          ]
        }
      ],
      orderStatusList: [
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1001273'), paramsKey: 'pickingNum', value: 0},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1001275'), paramsKey: 'pickingGoodsNum', value: 0},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1001277'), paramsKey: 'packagingNum', value: 0},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1001392'), paramsKey: 'boxingNum', value: 0}
      ],
      commodityStatusList: [
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005843'), paramsKey: 'onSaleNum', path: '/productCenter/productGoods?shelveStatus=1', value: 0},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005844'), paramsKey: 'noInventory', path: '/productCenter/productGoods?shelveStatus=2', value: 0},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005845'), paramsKey: 'rejectNum', path: '/productCenter/productGoods?auditStatus=2', value: 0}
      ],
      tableColumns: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002212'),
          key: 'ranking',
          align: 'center',
          width: 100,
          render(h, params) {
            let backColor = '#AAAAAA';
            if (params.index < 3) {
              backColor = '#1890FF';
            }
            return h('span', {
              style: {
                backgroundColor: backColor,
                width: '22px',
                height: '22px',
                borderRadius: '13px',
                color: '#fff',
                textAlign: 'center',
                lineHeight: '22px',
                display: 'inline-block'
              }
            }, params.index + 1);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000725'),
          key: 'commodity',
          align: 'left',
          minWidth: 250,
          render(h, params) {
            let imgPath = '';
            if (params.row._productInfo) {
              let skuImgPath = params.row._productInfo.primaryImage;
              let spuImgPath = params.row._productInfo._spuInfo.primaryImage
              imgPath = skuImgPath ? skuImgPath : spuImgPath;
              let list = [
                v.tableImg(h, params, null, imgPath),
                h('div', {
                  class: 'text-align-left pl10'
                }, [
                  h('Alink', {
                    props: {
                      linkName: params.row._productInfo._spuInfo.name,
                      textAlign: 'left',
                      className: 'mb5',
                      AlinkShow: false
                    },
                  }),
                  h('p', {class: 'flex align-items-center'}, alias47916751af154eb5b47cd5de9d34633d.t('key1000492') + params.row._productInfo._spuInfo.spu)
                ])
              ];
              return h('div', {
                class: 'flex ptb10',
              }, list)
            }
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002172'),
          key: 'totalPackageInfoNum',
          align: 'left',
          width: 120
        },
      ],
      tableData: [],
      dateValue: 'sevenDaySales',
      dateList: [
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005846'), value: 'sevenDaySales', active: true},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1005847'), value: 'thirtyDaySales', active: false},
      ],
      trendType: 'totalProductGoodsCost',
      trendTypeList: [
        {value: 'totalProductGoodsCost', title: alias47916751af154eb5b47cd5de9d34633d.t('key1002171'), name: alias47916751af154eb5b47cd5de9d34633d.t('key1005848')},
        {value: 'totalOrderQuantity', title: alias47916751af154eb5b47cd5de9d34633d.t('key1002172'), name: alias47916751af154eb5b47cd5de9d34633d.t('key1005849')}
      ],
      sevenDaySalesList: [], // 近7天的数据
      thirtyDaySalesList: [], // 近30天的数据
    };
  },
  mounted() {
    this.initEchart();
  },
  created() {
    this.getYmsSupplierCommonData();
    this.getTrendMapData();
  },
  methods: {
    // 获取数据看板信息（卡片、订单状态、商品管理）
    getYmsSupplierCommonData() {
      let v = this;
      if (v.getPermission('YmsSupplierCommon_index')) {
        v.axios.get(api.get_YmsSupplierCommon_index).then((response) => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              let newData = Object.assign({}, data.rptSupplierPackageSalesTotalBo, data.rptSupplierProductSalesTotalBo,
                data.rptPackageSaleTimeResult, data.rptProductSubscriptionResult);
              v.handleCardData(newData);
              v.handleOrderStatusData(data.packageInfoStatusResult);
              v.handleCommodityStatusData(data.productInfoResult);
            }
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 处理卡片的数据
    handleCardData(data) {
      let v = this;
      if (data) {
        let keyList = [
          'monthSalesVolume',
          'lastMonthSalesVolume',
          'yesterdaySalesVolume',
          'todaySalesVolume'
        ];
        v.dataPanelList.map((item) => {
          for (let key in data) {
            if (item.paramsKey === key) {
              if (keyList.includes(key)) {
                item.total = v.formatCurrency(data[key]);
              } else {
                item.total = data[key];
              }
            }
          }
          if (item.list.length > 0) {
            item.list.map((ele) => {
              for (let key in data) {
                if (ele.paramsKey === key) {
                  if (keyList.includes(key)) {
                    ele.value = v.formatCurrency(data[key]);
                  } else {
                    ele.value = data[key];
                  }
                }
              }
            })
          }
        })
      }
    },
    // 处理订单状态的数据
    handleOrderStatusData(data) {
      let v = this;
      if (data) {
        v.orderStatusList.map((item) => {
          for (let key in data) {
            if (item.paramsKey === key) {
              item.value = data[key];
            }
          }
        });
      }
    },
    // 处理商品管理的数据
    handleCommodityStatusData(data) {
      let v = this;
      if (data) {
        v.commodityStatusList.map((item) => {
          for (let key in data) {
            if (item.paramsKey === key) {
              item.value = data[key];
            }
          }
        })
      }
    },
    // 点击商品管理状态
    selectCommodityBtn(data) {
      let v = this;
      const {href} = v.$router.resolve({
        path: data.path
      });
      window.open(href, '_blank');
    },
    // 选择天数
    selectDateBtn(data, index) {
      let v = this;
      v.dateValue = data.value;
      v.handleTrendChartData(data.value, v.trendType);
      v.dateList.map((item, idx) => {
        item.active = false;
        if (idx === index) {
          item.active = true;
        }
      })
    },
    // 切换选择趋势图类型
    selectChange(value) {
      this.handleTrendChartData(this.dateValue, value);
    },
    // 获取趋势图-热销商品的数据
    getTrendMapData() {
      let v = this;
      if (v.getPermission('YmsSupplierCommon_getTrendMap')) {
        v.axios.get(api.get_YmsSupplierCommon_getTrendMap).then((response) => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              v.handleHotCommodityData(data.hotSellingProductInfoList);
              v.sevenDaySalesList = data.sevenDaySales;
              v.thirtyDaySalesList = data.thirtyDaySales;
              // 默认查按销售额、近7天的数据
              v.handleTrendChartData('sevenDaySales', 'totalProductGoodsCost');
            }
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 处理热销商品的数据
    handleHotCommodityData(data) {
      let v = this;
      let productGoodsIds = [];
      if (data && data.length > 0) {
        data.map((item) => {
          if (item.productGoodsId) {
            productGoodsIds.push(item.productGoodsId);
          }
        });
        let list = v.uniqueFunc(productGoodsIds);
        if (list.length > 0) {
          (async () => {
            v.tableData = await v.getProductInfoByIds(list, data, false, false,
              false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
          })();
        }
      }
    },
    // 处理趋势图的数据
    handleTrendChartData(dateType, type) {
      let v = this;
      let dateList = [];
      let dataList = [];

      function handleData(data) {
        if (data.length > 0) {
          dateList = data.map((item) => {
            if (item.shipedTime) {
              let date = v.$uDate.getDateTime(item.shipedTime, 'monthTime');
              return date;
            }
          });
          dataList = data.map((item) => {
            let number = item[type] ? item[type] : 0;
            return number;
          });
          v.initEchart(dateList, dataList);
        }
      }

      // 近7天
      if (dateType === 'sevenDaySales') {
        handleData(v.sevenDaySalesList)
      }
      // 近30天
      if (dateType === 'thirtyDaySales') {
        handleData(v.thirtyDaySalesList)
      }
    },
    // 初始化趋势图
    initEchart(dateList, dataList) {
      let v = this;
      if (myCharts != null && myCharts != "" && myCharts != undefined) {
        myCharts.dispose();
      }
      myCharts = this.$echarts.init(document.getElementById('main'), null, {
        width: 'auto',
        height: '400px',
      });
      let name = '';
      v.trendTypeList.map((item) => {
        if (item.value === v.trendType) {
          name = item.name;
        }
      });
      let option = {
        xAxis: {
          type: 'category',
          data: dateList
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let data = params[0];
            let values = data.value;
            if (v.trendType === 'totalProductGoodsCost') {
              values = v.formatCurrency(data.value);
            }
            let circle = '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:8px;height:8px;background-color:' + data.color + '"></span>';
            let html = data.name + '<br/>' + circle + data.seriesName + values;
            return html;
          }
        },
        series: [
          {
            data: dataList,
            type: 'line',
            smooth: true,      //true 有弧度 ，false 没弧度（直线）
            symbol: 'circle',   //将小圆点改成实心 不写symbol默认空心
            symbolSize: 8,    //小圆点的大小
            name: name,
            itemStyle: {
              normal: {
                color: '#389DFB', // 小圆点颜色
                lineStyle: {
                  color: '#66B4FC' // 线条颜色
                }
              }
            },
          }
        ]
      };
      myCharts.setOption(option);
      setTimeout(() => {
        myCharts.resize();
      }, 200);
    }
  }
};
</script>

<style lang="less" scoped>
.data_panel_box {
  .card_box {
    .card_box_item {
      background-color: #fff;
      border-radius: 5px;
      padding: 20px;
      min-height: 230px;

      .title_box {
        display: flex;
        justify-content: space-between;

        .title {
          color: #00000072;
          font-size: 15px;
        }

        .reset_tooltip_style {
          /deep/ .ivu-tooltip-inner {
            max-width: 550px;
          }
        }
      }

      .account {
        margin-top: 15px;
        color: #000000;
        display: flex;
        align-items: center;

        .currency {
          font-size: 22px;
        }

        .price {
          font-family: 'Arial Normal', 'Arial', sans-serif;
          font-weight: 400;
          font-size: 24px;
        }
      }

      .relevant_content {
        display: flex;
        margin-top: 15px;

        .relevant_title {
          font-size: 14px;
          color: #00000072;
          margin-right: 10px;
        }

        .currency {
          font-size: 15px;
          color: #000000;
        }

        .price {
          color: #000000;
          font-size: 16px;
        }
      }
    }
  }

  .card_content_box {
    .title_box {
      color: #333;
      font-size: 17px;
      font-weight: bold;
    }

    .order_status_box {
      padding: 10px 25px;
      display: flex;
      justify-content: space-between;
    }

    .status_item_styles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-family: 'Arial Normal', 'Arial', sans-serif;
        font-weight: 400;
        font-size: 15px;
        color: #00000072;
      }

      .text {
        font-family: 'Arial Normal', 'Arial', sans-serif;
        font-weight: 400;
        font-size: 32px;
        color: #000000;
        margin-top: 15px;
      }
    }

    .hoverStyles:hover {
      .title, .text {
        color: #2D8CF0;
        cursor: pointer;
      }
    }

    .title_content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        font-size: 14px;
        color: #2D8CF0;
        cursor: pointer;
      }
    }

    .commodity_status_box {
      padding: 10px 25px;
      display: flex;
      align-items: center;

      .commodity_status_content {
        flex: 7.5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 80px;
      }

      .line_box {
        display: inline-block;
        width: 1px;
        height: 85px;
        background-color: #D7D7D7;
      }

      .release_products_box {
        flex: 2.5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 60px;
        text-decoration: initial;

        .title {
          margin-top: 10px;
          color: #2D8CF0;
          font-size: 15px;
        }
      }
    }

    .options_box {
      display: flex;
      align-items: center;

      .date_box {
        display: flex;
        align-items: center;

        .date_box_item {
          font-size: 14px;
          color: #666;
          cursor: pointer;
          margin-right: 20px;
        }

        .activeColor {
          color: #2D8CF0;
        }
      }
    }
  }

  .reset_card_style {
    /deep/ .ivu-card-head {
      padding: 0 16px;
      height: 46px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
